<template>
  <!-- 学习记录 -->
  <div class="main-contain">
    <div class="search">
      <div class="leftcontain">
        <div class="left1">
          <div class="text">关键字:</div>
          <el-input
            class="elinput"
            size="small"
            v-model="queryParams.keyword"
            placeholder="请输入内容"
          >
            <i class="el-icon-search el-icon_search" slot="suffix"> </i>
          </el-input>
        </div>
      </div>
      <div class="rightcontain">
        <div class="bt" @click="reset">重置</div>
        <div class="bt bt1" @click="search">查询</div>
      </div>
    </div>
    <div class="contain">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="姓名" prop="name"> </el-table-column>
        <el-table-column label="实验名称" prop="module_name"> </el-table-column>
        <el-table-column label="实验开始时间" prop="start_time">
        </el-table-column>
        <el-table-column label="实验结束时间" prop="end_time">
        </el-table-column>
        <el-table-column label="实验成绩" prop="score"> </el-table-column>
      </el-table>
      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="10"
          :current-page="queryParams.page"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getvrstudylist } from "@/api/vrstudy";
import dayjs from "dayjs";
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      total: 0,
      tableData: [],
      queryParams:{
         vr_id: "",
          keyword: "",
          page: 1,
          per_page: 10
      }
    };
  },
  methods: {
    initdata(data) {
      this.queryParams.vr_id = data.id;
      this.getvrstudylist();
    },
    search() {
      this.queryParams.page = 1
      this.getvrstudylist();
    },
    reset() {
      this.queryParams.keyword = "";
       this.queryParams.page = 1
        this.queryParams.per_page = 10
      this.getvrstudylist();
    },
    //页码发生改变时
    pageChange(val) {
      this.queryParams.page = val
      this.getvrstudylist();
    },
    getvrstudylist() {
      this.tableData = [];
      getvrstudylist(this.queryParams)
        .then((response) => {
          if (response.data) {
            this.total = response.data.total;
            if (response.data.data && response.data.data.length > 0) {
              response.data.data.forEach((item) => {
                this.tableData.push({
                  name: item.name,
                  user_sn: item.user_sn,
                  score: item.score,
                  module_name: item.module_name,
                  start_time: dayjs
                    .unix(item.start_time)
                    .format("YYYY-MM-DD HH:mm:ss"),
                  end_time: dayjs
                    .unix(item.end_time)
                    .format("YYYY-MM-DD HH:mm:ss"),
                });
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  position: relative;
  background: #ffffff;
  .search {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    .leftcontain {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .left1 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 200px;
          ::v-deep .el-input__suffix {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .left2 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text {
          margin-right: 10px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #888888;
        }
        .elinput {
          width: 300px;
        }
      }
    }
    .rightcontain {
      display: flex;
      justify-content: center;
      align-items: center;
      .bt {
        margin-right: 10px;
        width: 64px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        font-size: 14px;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }
      .bt1 {
        margin-right: 10px;
        background: #3d84ff;
        color: white;
      }
    }
  }
  .contain {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    background: #ffffff;
    .table-head-op {
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        user-select: none;
        white-space: nowrap;
      }
      .elselect {
        margin-left: 10px;
      }
    }
    .state {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #288add;
      user-select: none;
      cursor: pointer;
    }
    .elpagination {
      margin-top: 20px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .elpagination-title {
        margin-right: 4px;
      }
    }
  }
}
</style>
