<template>
  <!-- 实验指南 -->
  <div class="main-contain">
    <div class="itemcontain">
      <template v-if="guide">
        <div class="text" v-html="guide"></div>
      </template>
      <template v-else>
        <div class="is-empty" style="text-align: center;">
          <img :src="no_math">
          <div style="font-size: 24px; font-weight: bold; color: #b5a2a2;margin-bottom: 30px;">
            <span>暂无实验指南</span>
          </div>
        </div>
      </template>

    </div>
  </div>
</template>
<script>
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      testimage: require("@/assets/test.png"),
      no_math: require('@/assets/no_math1.png'),
      guide: "",
    };
  },
  methods: {
    initdata(data) {
      this.guide = data.guide;
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  background: #ffffff;
  // width: 100%;
  .itemcontain {
    // border: 1px solid red;
    margin-top: 20px;

    ::v-deep img {
      width: 90%;
    }

    .text {
      text-indent: 2em;
      line-height: 25px;
      margin: 0 auto;
      margin-top: 10px;
      font-size: 15px;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>
